import dashboard from './dashboard';
// import pages from './pages';
import {utilities,Adsmanagement }from './utilities';
import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, utilities,Adsmanagement, other]
};

export default menuItems;
