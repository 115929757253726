// assets
import { IconBrandChrome, IconHelp, IconSettings, IconPlugConnectedX , IconMail} from '@tabler/icons';

// constant
const icons = { IconBrandChrome, IconHelp ,IconSettings,IconPlugConnectedX ,IconMail};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
  id: 'sample-docs-roadmap',
  type: 'group',
  children: [
    {
      id: 'sample-offer',
      title: 'Policy Page',
      type: 'item',
      url: '/Mainlayout/sample-page/Policy',
      icon: icons.IconBrandChrome,
      breadcrumbs: false
    },
    {
      id: 'sample-SEO',
      title: 'SEO',
      type: 'item',
      url: '/Mainlayout/sample-page/SEO',
      icon: icons.IconHelp,
      breadcrumbs: false
    },
    {
      id: 'sample-setting',
      title: 'Setting',
      type: 'item',
      url: '/Mainlayout/sample-page/Setting',
      icon: icons.IconSettings,
      breadcrumbs: false
     
    },
    {
      id: 'sample-plugins',
      title: 'Plugins',
      type: 'item',
      url: '/Mainlayout/sample-page/plugins',
      icon: icons.IconPlugConnectedX ,
      breadcrumbs: false
    },
    {
      id: 'sample-subscribers',
      title: 'Subscribers',
      type: 'item',
      url: '/Mainlayout/sample-page/subscribers',
      icon: icons.IconMail,
      breadcrumbs: false
    }
  ]
};

export default other;
