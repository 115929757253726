// assets
import { IconCategory, IconBrandAppstore, IconGiftCard, IconPercentage, IconAd } from '@tabler/icons';

// constant
const icons = {
  IconCategory,
  IconBrandAppstore,
  IconGiftCard,
  IconPercentage,
  IconAd
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
  id: 'utilities',
  title: 'Coupons Management',
  type: 'group',
  children: [
    {
      id: 'util-typography',
      title: 'Categories',
      type: 'item',
      url: '/Mainlayout/utils/util-typography',
      icon: icons.IconCategory,
      breadcrumbs: false
    },
    {
      id: 'util-color',
      title: 'Stores/Brand',
      type: 'item',
      url: '/Mainlayout/utils/util-color',
      icon: icons.IconBrandAppstore,
      breadcrumbs: false
    },
    {
      id: 'util-shadow',
      title: 'Coupons',
      type: 'item',
      url: '/Mainlayout/utils/util-shadow',
      icon: icons.IconGiftCard,
      breadcrumbs: false
    },
    {
      id: 'util-offers',
      title: 'Special Offers',
      type: 'item',
      url: '/Mainlayout/utils/util-special',
      icon: icons.IconPercentage,
      breadcrumbs: false
    }
   
  ]
};
const Adsmanagement = {
  id: 'Ads',
  title: 'Ads Managememt',
  type: 'group',
  children: [
    {
      id: 'Ads_1',
      title: 'Ads',
      type: 'item',
      url: '/Mainlayout/utils/util-Ads',
      icon: icons.IconAd,
      breadcrumbs: false
    },
   
  ]
};

export  {utilities, Adsmanagement};
